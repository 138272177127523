<template>
  <b-card no-body>
    <b-card-header class="p-1">
      <h5>{{ $t("Filters") }}</h5>
    </b-card-header>
    <b-card-body class="p-1">
      <b-row>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>Facility</label>
          <v-select
            v-model="facilityId"
            class="w-100"
            :options="facilityOptions"
            :reduce="(facility) => facility.pivot.facility_id"
            label="name"
          />
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2 mt-2 ml-auto" align="end">
          <b-button variant="warning" @click="resetFilter" class="mr-1">
            {{ $t("Clear") }}
          </b-button>
          <b-button
            :disabled="!facilityId"
            variant="primary"
            @click="
              $router.push({ name: 'payment-cash-history', params: { id: facilityId } })
            "
          >
            <span>{{ $t("Search") }}</span>
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BFormInput,
  BButton,
} from "bootstrap-vue";
import vSelect from "vue-select";

import axiosF from "@/services/admin/facilities";
export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    vSelect,
  },
  data() {
    return {
      facilityId: null,
      facilityOptions: [],
      userId: JSON.parse(localStorage.getItem("userData")).id,
    };
  },
  mounted() {
    this.getFacilityOptions();
  },
  methods: {
    resetFilter() {
      this.facilityId = null;
    },
    getFacilityOptions() {
      axiosF.facilityPartners(this.userId).then(({ registro }) => {
        this.facilityOptions = registro;
      });
    },
  },
};
</script>

<style>
</style>