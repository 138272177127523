<template>
  <div>
    <!-- <SearchFacility /> -->
    <b-card>
      <div class="m-1">
        <TitleTable titleTable="Daily Boxes" iconTable="DollarSignIcon" />
        <!-- Table Top -->
        <b-row>
          <!-- {{ $t("Search") }} -->
          <b-col cols="12" align-self="end">
            <div class="d-flex align-items-center justify-content-end">
              <GoBack class="mr-2" />
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        :items="items"
        :fields="fields"
        hover
        bordered
        responsive
        primary-key="id"
        class="position-relative"
        show-empty
        empty-text="No matching records found"
      >
        <template #cell(facilities)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar size="32" :src="data.item.facilities.avatar" />
            </template>
            <b-link
              :to="{
                name: 'payment-cash-history',
                params: { id: data.item.facilities.id },
              }"
            >
              <span
                class="
                  font-weight-bold
                  d-block
                  text-nowrap
                  d-flex
                  flex-column
                  align-items-start
                "
              >
                <span>
                  {{ data.item.facilities.name }}
                </span>
                <span>
                  {{ data.item.facilities.lastname }}
                </span>
              </span>
            </b-link>
          </b-media>
        </template>
      </b-table>
    </b-card>
    <ToastNotification ref="toast" />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BBadge,
  BAvatar,
  BMedia,
  BLink,
  BButton,
  VBTooltip,
} from "bootstrap-vue";

import ToastNotification from "@/components/ToastNotification";
import TitleTable from "@/components/TitleTable.vue";
import GoBack from "@/components/ButtonBack.vue";

import axiosC from "@/services/cash-account";

import SearchFacility from "./SearchFacility";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BBadge,
    BAvatar,
    BButton,
    BMedia,
    BLink,
    VBTooltip,
    ToastNotification,
    TitleTable,
    GoBack,
    SearchFacility,
  },
  data() {
    return {
      fields: [
        // A column that needs custom formatting
        { key: "facilities", label: "Facility", sortable: true },

        { key: "frontdesks", label: "Frontdesk", sortable: true },
        // A regular column
        { key: "current_balance", label: "current balance" },
        { key: "current_descriptive" },
        { key: "income" },
        { key: "opening" },
        { key: "closure" },
      ],
      items: null,
      perPage: 10,
      perPageOptions: [10, 25, 50, 100],
    };
  },
  mounted() {
    this.getCash();
  },
  methods: {
    getCash() {
      axiosC.cashGlobalDaily().then(({ registro }) => {
        this.items = registro;
      });
    },
  },
};
</script>

<style>
</style>